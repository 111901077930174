import {Data} from "./data";
import {Order} from "./order";
import {Discount} from "./discount";
import {Benefit} from "./benefit";
import {Item} from "./item";

export class Condition extends Data {

    class: string = "Condition";
    type: string;
    code: string;
    member: boolean;
    amount: number;
    applicable: number = 1; //????
    discounted: boolean = false;
    display: string;
    prefix: string;
    level: string;
    tiers: string [];
    count: number = 1;//??

    constructor(){
        super();
    }

    protected toProperty(name: string, value) {
        return value;
    }

    //applying discount and get discount amount
    apply(order: Order, promo: Discount, benefit: Benefit):number {

        if (this.level == 'item') {
            let total = 0;

            order.orderitems.forEach(oi=>{

                if ((this.type == 'item' && oi.items && oi.items.length > 0 && (oi.items[0].id === this.code || this.code=='all')) ||
                    (this.type == 'itemgroup' && oi.groups && oi.groups.length > 0 && (oi.groups[0].id === this.code || this.code=='all')) ||
                    (this.type === "exitemgroup" && oi.groups && oi.groups.length > 0 && (oi.groups[0].id !== this.code || this.code=='all'))) {

                    let discount = benefit.apply(order, this, promo, oi);
                    total += discount;

                    if (discount && promo.backendId) {
                        oi.discountCode = promo.backendId;
                    }
                    // oi.discounts.push(promo);
                    oi.discountDesc = promo.desc? promo.desc : promo.name;

                }
            });

            return total

        } else {

            if (this.type === "all" || this.type === "cardprefix") promo.level = "order";

            return benefit.apply(order, this, promo);
        }
    }

    //check conditions to use the discount
    isSatisfied(order: Order, promo: Discount): boolean {
        let ok: boolean = false;

        switch (this.type)
        {
            //guest=== all
            case 'all': case 'guest':{
                ok = true;
                break;
            }

            //check member type, check member tier
            case 'member': {
                if (this.member && order.member) {
                    if (this.tiers) {
                        if (!order.member.tier || this.tiers.indexOf(order.member.tier) < 0) {
                            ok = false;
                            break;
                        }
                    }
                    ok = true;
                }
                break;
            }

            case 'total': {
                if (this.amount) {
                    let total = this.discounted? order.grandTotal : order.total;

                    //special deal
                    if (!this.discounted && promo.benefits && promo.benefits[0] && promo.benefits[0].type == "unlock") {
                        if (promo.benefits[0].code) {
                            let code = promo.benefits[0].code;
                            order.orderitems.forEach(oi=>{
                                if (oi.categoryId == code && oi.quantity > 0) {
                                    total -= oi.total * oi.quantity;
                                }
                            });
                        }
                    }

                    if (total >= this.amount) {
                        this.applicable = Math.floor(total / this.amount);
                        ok = true;
                    } else {
                        this.applicable = 0;
                    }
                }
                break;
            }

            //ordered item
            case 'item':{
                if (this.code) {
                    if (this.code == "all") {
                        ok = true;
                        break;
                    }

                    for (let oi of order.orderitems) {

                        if (ok) break;

                        for (let item of oi.items){
                            if (item.id === this.code) {

                                //TODO?
                                ok = true;
                                break;
                            }
                        }
                    }
                }
                break;
            }

            //ordered items inside specific itemgroups
            case 'itemgroup': {
                if (this.code) {

                    let total = 0;
                    for (let i = 0; i < order.orderitems.length; i++) {
                        let oi = order.orderitems[i];
                        if (oi.groups && oi.groups.length > 0){
                            for (let group of oi.groups){
                                if (group.id === this.code && group.items) {
                                    total += oi.quantity;
                            ok = true;
                                    // break;
                                }
                            }
                        }

                        ok = total >= this.count;

                        if (ok) break;
                    }
                }
                break;
            }

            //TODO add use code????
            case 'code': {
                if (this.code && order.discountCode && this.code === order.discountCode) {
                    ok = true;
                }
                break;
            }

            //TODO check when payment -test if card no can be got??????? when check
            case 'cardprefix': {
                if (order.payment && order.payment == "creditcard" && this.prefix) {
                    //TODO
                    let payment = order.payments[0];
                    if (payment.cardNo.indexOf(this.prefix) === 0) {
                        ok = true;
                    }
                }
                break;
            }

            //TODO:???
            case 'exitemgroup': {
                if (this.code && order.orderitems) {
                    for (let oi of order.orderitems) {
                        if (oi.id !== this.code) {
                ok = true;
                break;
            }
                    }
                }
                break;
            }

            case "offer": {
                let offer = order.offers.find(offer=> { return offer.backendId === this.code });

                if (offer) ok = true;
                break;
            }

            case "welcome": {
                if (order.member) {
                    //TODO get welcomed???
                    // if (order.member.membership
                }
                break;
            }

            default: break;
        }
        console.log("isSatisfied: ", ok);

        return ok;
    }

    getLevel() {
        if (this.level) return this.level;

        switch (this.type)
        {


            case 'itemgroup': case 'item': case 'exitemgroup': {
                this.level = "item";
                break;
            }
            case 'all':
            case 'guest':
            case 'member':
            case 'total':
            case 'code':
            case 'cardprefix': {
                this.level = "order";
                break;
            }

            default: {
                this.level = "";
                break;
            }
        }

        return this.level;
    }

    getDisplay() {
        if (this.display) return this.display;

        switch (this.type)
        {

            case 'guest':{
                this.display = "Guest";
                break;
            }
            case 'member': {
                this.display = "Member";
                break;
            }
            case 'all':
            case 'total':
            case 'itemgroup':
            case 'item':
            case 'code':
            case 'cardprefix':
            case 'exitemgroup':
            default: {
                this.display = "";
                break;
            }
        }

        return this.display;
    }

    setCode(code: string) {
        this.code = code;
    }
    setDisplay(display: string) {
        this.display = display;
    }

    resolve() { //TODO???
        if (!this.code) return;
        if (this.type == 'item'){

        } else
        if (this.type == 'exitemgroup' || this.type == 'itemgroup'){

        }
    }

    //TODO???
    setDiscountPrices(benefit: Benefit){
        if (!benefit.isItemDiscount()) return;

        //todo set item new price>>>?????
        // let item: Item = benefit.orderItemFindItem(orderItem, this.code)

    }

    //TODO??
    isMainItemOnly(): boolean {
        if (this.type == 'itemgroup' || this.type == 'total') return true;
        return false;
    }

    static cloneCondition(cond: Condition) {
        let c = new Condition();

        if (cond.type) c.type = cond.type;
        if (cond.code) c.code = cond.code;
        if (cond.member) c.member = cond.member;
        if (cond.amount) c.amount = cond.amount;
        if (cond.applicable) c.applicable = cond.applicable;
        if (cond.discounted) c.discounted = cond.discounted;
        if (cond.display) c.display = cond.display;
        if (cond.prefix) c.prefix = cond.prefix;
        if (cond.level) c.level = cond.level;
        if (cond.tiers) c.tiers = [...cond.tiers];
        c.getLevel();
        c.getDisplay();


        return c;
    }


}
