import { Category } from './category';
import { ItemGroup } from './item-group';
import { Data } from './data';
import { Discount } from './discount';

export class Item extends Data {

    class: string = "Item";
    id: string;
    name: string;
    price: number;
    price2: number;
    published: boolean;
    suspended: boolean;
    inventory: number = -1;
    quantity: number = 0;
    backendId: string;
    printName: string;
    start: number;
    end: number;
    points: number = 0;

    groupIds: string[] = [];
    mgroupIds: string[] = [];

    groups: ItemGroup[] = [];
    mgroup: string;
    group: string;
    mgroups: ItemGroup[] = [];
    tags: string[];

    origin: Item;
    modifier: boolean;

    combo: boolean = false;
    modifiable: boolean = false;
    printers: string[] = [];

    parentId: string;
    absentId: string;

    min: number;
    max: number;
    baseQty: number;
    title: string;

    extra: any;
    dinein: boolean;
    pgroupId:string;
    pgroup:ItemGroup;

    handling: number;
    discount: number;
    discountCode: string = "";


    variations:Item[];
    marker: string;
    periods: string[];

    isPrint: boolean = true;
    modes: string[];

    prefix:string = "";
    discounted: boolean = false;
    discounts: Discount[] = [];
    discountable: boolean;
    segments: string[];
    rating: number;
    discountedId:string;
    filters: string[];
    desc: string;

    protected toProperty(name: string, value) {

        switch (name) {

            case "groups":
                value = Data.toDataArray(ItemGroup, value);
                break;
            case "mgroups":
                value = Data.toDataArray(ItemGroup, value);
                break;
            default:
                value = super.toProperty(name, value);
                break;

        }

        return value;
    }

    static isSet(category: Category, item: Item) {

        if (item.groups && item.groups.length > 0) {
            return true;
        }
        if (category && category.groups && category.groups.length > 1) {

            return true;
        }

        return false;
    }

    static isModifiable(item: Item): boolean {
        let mgroups = item.mgroups;
        if (!mgroups || mgroups.length == 0) {
            return false;
        }
        return mgroups.some(g => g.items && g.items.length > 0);
    }

    public static isPublished(item: Item, now: number): boolean {

        if (!item.start && !item.end) return true;

        let greaterThanStart = true;
        let lessThanEnd = true;

        if (item.start) {
            greaterThanStart = now >= item.start;
        }

        if (item.end) {
            lessThanEnd = now <= item.end;
        }

        return greaterThanStart && lessThanEnd;

    }

    public static filterPublished(items: Item[], now: number){

        var result = [];

        if(!items || items.length == 0) return result;

        for(let item of items){
            if(item.published && Item.isPublished(item, now)){
                result.push(item);
            }
        }

        return result;
        
    }

    public static filterPublishedItems(items: Item[], cmap: any,catId:string,index:number,filter?:any){

        var result = [];
        var catIdWithIndex = catId + "." + index.toString();


        if(!items || items.length == 0) return result;

        

        for(let item of items){
            if(item.groups.length == 0){
                item.combo = false;
            }
       
            if(cmap && (cmap[catIdWithIndex] || cmap[catId])){
                var published = cmap[catIdWithIndex] ? cmap[catIdWithIndex] : cmap[catId];

                if(published && published[item.id]){
                    result.push(item);

                }

            }else{

                if(filter){
                    if(filter(item)){
                        result.push(item);
                    }

                }else if(item.published){

                    result.push(item);
                }

            }
        }

        return result;
        
    }

    static toItemsData(items: Item[]): any[] {
        var results: any[] = [];
        for (let item of items) {
            var tmp = Item.toItemData(item);
            results.push(tmp);
        }
        return results;

    }

    static toItemData(item: Item): any {
        var json = {};
        json["id"] = item.id;
        json["quantity"] = item.quantity;
        json["name"] = item.name;
        json["price"] = item.price;
        json["modifiable"] = item.modifiable;
        json["modifier"] = item.modifier;
        if(item.points > 0){
            json["usedPoints"] = item.points;
        }
        json["discounted"] = item.discounted;
        json["mgroups"] = item.mgroups ? ItemGroup.toItemGroupsData(item.mgroups):null;

        return json;

    }

    static getPrice(item: Item, level: number){

        var result = item.price;

        switch(level){
            case 1:
                result = item['price1'];
                break;
            case 2:
                result = item['price2'];
                break;
            case 3:
                result = item['price3'];
                break;
            case 4:
                result = item['price4'];
                break;
            case 5:
                result = item['price5'];
                break;
            case 6:
                result = item['price6'];
                break;
            case 7:
                result = item['price7'];
                break;
            case 8:
                result = item['price8'];
                break;  
            default:
                result = item['price1'];
                break;
        }

        if(!result && result != 0){
            result = item.price;
        }

        return result;

    }

}
