import { Ad } from './ad';
import { Store } from './store';
import { Data } from './data';
import { Discount } from './discount';

export class Reward extends Data {
    class: string = "Reward";
    id: number;
    name: string;
    hint: string;
    publihed: boolean;
    backendId: string;
    price:number;
    start:number;
    end:number;
    discount: Discount;


    protected toProperty(name: string, value) {

        if(name == "discount") {
            return Data.toData(Discount,value);
        }

        return value;
    }

    static toRewardData(reward:Reward):any{
        var json ={};
        json["id"] = reward.id;
        json["name"] = reward.name;
        json["hint"] = reward.hint;
        json["publihed"] = reward.publihed;
        json["backendId"] = reward.backendId;
        json["price"] = reward.price;


        return json;
        
    }

    
}