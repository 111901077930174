import { Data } from './data';

export class Pos extends Data {

    class: string = "Pos";
    id: number;
    name: string;
    type:string;
    payments: string[] = [];
    host: string;
    lockTable: boolean = false;
    lockDuration: number;
    manualSync: boolean = false;
    extra: any = {};

    protected toProperty(name: string, value) {

        return value;
    }

    hasPayment(method: string) : boolean{

        if(!this.payments) return false;

        return this.payments.indexOf(method) >= 0;

    }

}