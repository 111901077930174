import { OpeningHours } from './opening-hours';
import { Data } from './data';
import * as moment from 'moment';

export class Period extends Data {
    class: string = "Period";
    id:string;
    name: string;
    code: string;
    start: number;
    end: number;
    startTime: string;
    endTime: string;
    backendId: string;
    cron:string;
    display:string;
    prefix:string;
    openings:OpeningHours;


    public static isTimeOpen(period: Period, time: number): boolean {
        
        var startTime = period.startTime;
        var endTime = period.endTime;

        
        var start = moment(startTime, "HH:mm");
        var end = moment(endTime, "HH:mm");

        var now = moment(time);

        start.year(now.year());
        start.month(now.month());
        start.dayOfYear(now.dayOfYear());

        end.year(now.year());
        end.month(now.month());
        end.dayOfYear(now.dayOfYear());

        if(end < start){
            console.log("stepped over time to next day!!!");
            if(now > end){
                end = end.add(24, 'hours');
            }else{
                start = start.add(-24, 'hours');
            }
        }

        var within = Period.between(start, end, now);

        return within;
    }

    private static between(start: any, end: any, time: any){

        var ms = time.valueOf();

        return ms >= start.valueOf() && ms < end.valueOf();

    }
}