import { Data } from './data';

export class Location extends Data {

    class: string = "Location";
    id: number;
    name: string;
    line: string;
    hint: string;
    longitude: number;
    latitude: number;
    province: string;
    full: string;
    phone: string;
  provinceCode: string;
}
