import { Publish } from './publish';
import { Pos } from './pos';
import { ItemGroup } from './item-group';
import { Period } from './period';
import { Store } from './store';
import { Category } from './category';
import { Data } from './data';
import { OpeningHours } from './opening-hours';
import { Item } from './item';
import { Brand } from './brand';
import { Setting } from './setting';
import { Badge } from './badge';
import { PriceRule } from './price-rule';
import { Discount } from './discount';

export class Menu extends Data {

    class: string = "Menu";
    badges: Badge[];
    id: string;
    name: string;
    categories: Category[];
    recommendation: Category;
    unlocks: Category[];
    gifts: Category[];
    stores: Store[];
    create: Date;
    update: Date;
    currency: string;
    periods: Period[];
    groups: ItemGroup[];
    subs: Menu[] = [];
    parentId: string;
    hidePrice: boolean;
    posId: number;
    pos: Pos;
    items: Item[];
    modes: string[];
    publish: Publish;
    quickCheckout: boolean;
    maxItems: number;
    serviceCharge: number;
    pickupCharge: number;
    pickupItemCharge: number;
    tax: number;
    stagePush: boolean;
    master: boolean;
    posSync: boolean;
    deliveryCharge: number;
    taxIncluded: boolean;
    minDeliveryTotal: number;
    maxDeliveryTotal: number;
    quickSingleItem: boolean;
    quickModifier: boolean;
    preselectItem: boolean;
    setLayout: string;
    pickupTime: boolean;
    catLayout: string;
    tcPrompt: string;
    settings: Setting;
    priceRules: PriceRule[];
    discounts: Discount[];
    cgroups: any[];

    //current price level of this menu item.price
    priceLevel: number = 1;

    protected toProperty(name: string, value) {

        switch (name) {
            case "badges":
                value = Data.toDataArray(Badge, value);
                break;
            case "categories":
                value = Data.toDataArray(Category, value);
                break;
            case "subs":
                value = Data.toDataArray(Menu, value);
                break;
            case "groups":
                value = Data.toDataArray(ItemGroup, value);
                break;
            case "stores":
                value = Data.toDataArray(Store, value);
                break;
            case "periods":
                value = Data.toDataArray(Period, value);
                break;
            case "pos":
                value = Data.toData(Pos, value);
                break;
            case "recommendation":
                value = Data.toData(Category, value);
                break;
            case "unlocks":
                value = Data.toDataArray(Category, value);
                break;
            case "gifts":
                value = Data.toDataArray(Category, value);
                break;        
            case "settings":
                value = Data.toData(Setting, value);
                break;
            case "items":
                value = Data.toDataArray(Item, value);
                break;
            case "priceRules":
                value = Data.toDataArray(PriceRule, value);
                break;    
            case "create":
            case "update":
                value = new Date();
                break;
            default:
                value = super.toProperty(name, value);
                break;
        }

        return value;
    }

}

