import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Data } from '../data/data';
import { Member } from '../data/member';
import { Brand } from '../data/brand';
import { Store } from '../data/store';
import { Pos } from '../data/pos';
import { environment } from 'src/environments/environment';
import { Station } from '../data/station';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {


  isPrd = environment.production;
  sid: string;
  constructor(private http: HttpClient) {
  }

  login() {
    const params = { 'username': 'mike.ma@aigens.com', 'password': 'aigens123' };
    const search: URLSearchParams = new URLSearchParams();
    search.set('locale', 'zh');
    // let params = {"sid": sid};
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    return this.http.post(`${this.getApi()}store/login.json?groupId=1000&locale=zh&country=hk&username=mike.ma@aigens.com&password=aigens123`, JSON.stringify(params)).pipe(map(jo => Data.toData(Member, jo['data'])));

  }
  getBrand(brandId: number) {
    return this.http.get(`${this.getApi()}menu/brand/${brandId}.json?groupId=100&locale=zh&country=hk&sid=${this.sid}`).pipe(map(jo => Data.toData(Brand, jo['data'])));
  }

  getStore(storeId: number) {
    return this.http.get(`${this.getApi()}menu/store/${storeId}.json?groupId=100&locale=zh&country=hk&sid=${this.sid}`).pipe(map(jo => Data.toData(Store, jo['data'])));
  }

  getPos(storeId: number) {
    return this.http.get(`${this.getApi()}menu/pos.json?storeId=${storeId}&groupId=100&locale=zh&country=hk&sid=${this.sid}`).pipe(map(jo => Data.toData(Pos, jo['data'])));
  }

  getStation(stationId: number) {
    return this.http.get(`${this.getApi()}store/station/${stationId}.json?groupId=100&locale=zh&country=hk&sid=${this.sid}`).pipe(map(jo => Data.toData(Station, jo['data'])));
  }

  getApi() {
    if (this.isPrd) {
      return 'https://aigensstoreapp.aigens.com/api/v1/';
    } else {
      return 'https://aigensstoretest.aigens.com/api/v1/';
    }
  }
}
