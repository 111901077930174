import {OpeningHours} from './opening-hours';
import {Period} from './period';
import {Menu} from './menu';
import {Pos} from './pos';
import {Brand} from './brand';
import {Category} from './category';
import {Data} from './data';
import {Location} from './location';
import {Printer} from "./printer";

export class Store extends Data {

    class: string = "Store";
    id: number;
    name: string;
    categories: Category[];
    location: Location;
    brand: Brand;
    brandId: number;
    features: string[] = [];
    handle: string;
    colors: {};
    currency: string;
    timezone: string;
    country: string;
    menuId: string;
    posId: number;
    groupId: number;
    languages: string[];
    language: string;
    pos: Pos;
    menu: Menu;
    closed: boolean;
    closedMessage: string;
    version: number;
    openings: OpeningHours;
    openings2: OpeningHours;
    printers: Printer[];
    periods: Period[];
    periods2: Period[];
    periodUsage: string[];
    periodUsage2: string[];
    printName: string;
    passcode: string;
    passcode2: string;
    master: boolean;
    gatag:string;
    ganame:string;

    get sign(): string {

        if (!this.currency) return "$";

        let cur = this.currency.toLowerCase();
        let sign = "$";

        if ("cny" == cur) {
            sign = "¥";
        } else if ("inr" == cur) {
            sign = "₹";
        } else {
            sign = "$";
        }

        return sign;
    }

    public static isOpeningHours(store: Store, time: number = new Date().getTime()): boolean {

        var oh = store.openings;
        if (!oh) return true;

        var brand = store.brand;
        if (brand && brand.openings) {
            var holidays = brand.openings;
            oh.ph1 = holidays.holidays;
        }

        let openings: OpeningHours = null;
        if (brand && brand.openings) {
            openings = brand.openings;
        }

        return OpeningHours.isTimeOpen(openings, oh, time);

    }

    public static hasFeature(store: Store, feature: string): boolean {
        if (store.features) {
            return store.features.indexOf(feature) >= 0;
        }
        return false;
    }

    hasFeature(feature: string): boolean {
        if (this.features) {
            return this.features.indexOf(feature) >= 0;
        }
        return false;
    }

    protected toProperty(name: string, value) {

        switch (name) {
            case "brand":
                value = Data.toData(Brand, value);
                break;
            case "menu":
                value = Data.toData(Menu, value);
                break;
            case "categories":
                value = Data.toDataArray(Category, value);
                break;
            case "location":
                value = Data.toData(Location, value);
                break;
            case "pos":
                value = Data.toData(Pos, value);
                break;
            case "printers":
                value = Data.toDataArray(Printer, value);
                break;
            case "periods":
                value = Data.toDataArray(Period, value);
                break;
            case "openings":
            case "openings2":
                value = Data.toData(OpeningHours, value);
                break;
            default:
                value = super.toProperty(name, value);
                break;
        }

        return value;
    }

}