import { Data } from './data';
import { OpeningHours } from './opening-hours';
import { Member } from './member';

export class PriceRule extends Data {
    class: string = "PriceRule";
    level: number = 1;
    name: string;
    id: string;
    priority: number;
    enabled:boolean;
    openings: OpeningHours;
    modes:string[];
    users:string[];

    protected toProperty(name: string, value) {

        switch (name) {
            case "openings":
                value = Data.toData(OpeningHours, value);
                break;
            default:
                value = super.toProperty(name, value);
                break;
        }

        return value;
    }

    private static isActive(rule: PriceRule, time: number): boolean {

        if(!rule.openings){
            return true;
        }

        
        return OpeningHours.isTimeOpen(null, rule.openings, time);
        
    }

    private static getUserScore(rule: PriceRule, member: Member): number{

        console.log("getUserScore2");

        var users = rule.users;
        if(!users || users.length == 0) return 0;

        //NOTE: Only support guest or member now

        var type = "guest";
        if(member){
            type = "member";
        }

        if(users.indexOf(type) >= 0){
            return 100;
        }else{
            return -10000;
        }

    }

    private static getModeScore(rule: PriceRule, mode: string): number{

        var modes = rule.modes;

        console.log("getModeScore2", mode);

        if(!modes || modes.length == 0) return 0;

        if(rule.modes.indexOf(mode) >= 0){
            return 100;
        }else{
            return -10000;
        }

        

    }

    public static getMatch(rule: PriceRule, time: number, mode: string, member: Member): number{

        var timeOk = PriceRule.isActive(rule, time);
        if(!timeOk){
            console.log("not active");
            return -10000;
        }
        var userScore = PriceRule.getUserScore(rule, member);
        var modeScore = PriceRule.getModeScore(rule, mode);

        console.log("userScore", userScore, "modeScore", modeScore);

        return userScore + modeScore;
        
    }
}