import { Data } from './data';

export class Station extends Data {
    class: string = "Station";
    id: number;
    //brandId: number;
    create: number;
    lastSeen: number;
    number: string;
    test: boolean;
    name: string;
    storeId: number;
    type:string;
    setting: any;
}