import { Data } from './data';

export class Member extends Data {

    class: string = "Member";
    id: number;
    sessionId: string;
    username: string;
    email: string;
    regions: string[];
    avatarUrl: string;
    language: string;
    name: string;
    gender: string;
    firstName: string;
    lastName: string;
    groupId: number;
    brandId: number;
    storeId: number;
    admin: boolean = false;
    readOnly: boolean = false;
    phone: string;
    backendId: string;
    type:string;
    sessionTimeout: number = 0;
    birthdate: number;
    marketing: boolean = false;
    cardNo: string;
    extra: any;
    behavior: any;
    membership: any;
    tier: string;

    static toMemberData(member:Member):any{
        var json ={};
        json["id"] = member.id;
        json["sessionId"] = member.sessionId;
        json["username"] = member.username;
        json["storeId"] = member.storeId;
        json["type"] = member.type;
        json["regions"] = member.regions;
        json["language"] = member.language;
        json["name"] = member.name;
        json["gender"] = member.gender;
        json["firstName"] = member.firstName;
        json["lastName"] = member.lastName;
        json["groupId"] = member.groupId;
        json["brandId"] = member.brandId;
        json["storeId"] = member.storeId;
        json["phone"] = member.phone;
        json["backendId"] = member.backendId;
        json["birthdate"] = member.birthdate;
        json["marketing"] = member.marketing;
        json["cardNo"] = member.cardNo;
        json["membership"] = member.membership;
        json["tier"] = member.tier;


        return json;
        
    }
}