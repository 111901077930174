/**
 * Created by mikema on 20/6/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order } from '../data/order';
import { Data } from '../data/data';

/**
 * Created by mikema on 5/4/17.
 */

@Injectable()
export class OrderService {


    constructor(private http: HttpClient, private configService: ConfigService) {
    }


    getStoreOrders(storeId: string, sid: string, watchingStatus): Observable<Order[]> {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return this.http.get(`${this.configService.getApi()}menu/order.json?groupId=1000&locale=zh&country=hk&storeId=${storeId}&status=${watchingStatus}&start=${today.getTime()}&sid=${sid}`).pipe(map(data => Data.toDataArray(Order, data['data'])));
    }

}
