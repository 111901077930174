import { Ad } from './ad';
import { Store } from './store';
import { Data } from './data';
import { OpeningHours } from './opening-hours';
import { Link } from './link';

export class Brand extends Data {
    class: string = "Brand";
    id: number;
    name: string;
    features: string[] = [];
    colors:{};
    groupId: number;    
    handle: string;
    theme: string;
    themes: any = {};
    stores: Store[];
    ads: Ad[];
    extra: any = {};
    printName: string;
    openings: OpeningHours;
    links: { string: Link };
    payments: any[] = [];
    gatag:string;
    ganame:string;
    crmId: string;

    protected toProperty(name: string, value) {

        if (name == "stores") {
            return Data.toDataArray(Store, value);
        }else if (name == "ads") {
            return Data.toDataArray(Ad, value);
        }



        return value;
    }

    hasFeature(feature: string) : boolean{
        if(this.features){
            return this.features.indexOf(feature) >= 0;
        }
        return false;
    }
}