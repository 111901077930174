import {Data} from "./data";
import {Condition} from "./condition";
import {Order} from "./order";
import {Discount} from "./discount";
import {OrderItem} from "./order-item";
import {Item} from "./item";
import {Category} from "./category";

export class Benefit extends Data {

    class: string = "Benefit";
    type: string;
    amount: number;
    code: string;
    max: number = 1;
    percent: number;
    applied: boolean = false;
    used: number = 0;
    display: string;

    protected toProperty(name: string, value) {
        return value;
    }

    apply(order: Order, condition: Condition, promo: Discount, orderItem: OrderItem = null): number {

        let discount: number = 0;
        switch (this.type)
        {
            case 'amount': {
                if (this.max > 0) {
                    let count: number = 1;

                    if (condition.type === "total") {
                        let total: number = condition.discounted? order.grandTotal : order.total;
                        count = Math.floor(total / condition.amount);
                        if (count > this.max) count = this.max;

                    } else if (condition.level === "item") {
                        //condition type = item or itemgroup or exitemgroup
                        if (orderItem.quantity) count = orderItem.quantity;
                    }

                    // if (this.amount) discount = this.round(this.amount * count);
                    if (orderItem) orderItem.discount = discount;

                    promo.available += count;
                }
                break;
            }

            case 'percent': {
                if (this.percent) {
                    let count = 1;
                    if (condition.level == "order") {

                        let discountableTotal = order.total; //TODO not yet consider cond.discounted = true????

                    order.orderitems.forEach(oi=>{
                        oi.items.forEach(item => {
                            if ((item.marker && item.marker=="alone" && !item.discountable) || (item.marker!=="alone" && !oi.items[0].discountable)) {
                                discountableTotal -= item.price * (item.quantity * oi.quantity);
                            }
                        });
                    });
                    discount = discountableTotal * this.percent / 100.0;

                    } else if (condition.level == "item") {
                        if (orderItem.quantity) count = orderItem.quantity;
                        //todo? if (this.max) count = Math.min(orderItem.quantity, this.max);

                        // discount = this.round(orderItem.total * this.percent / 100.00 * count);
                        orderItem.discount = discount;

                    }
                    promo.available += count;

                }
                break;
            }

            //TODO NOT YET
            case 'item_amount': {

                if (this.code && this.orderItemFindItem(orderItem, this.code)) {
                    discount = this.amount;
                    promo.available++;
                }

                break;
            }

            //TODO NOT YET
            case 'itemgroup_percent': {
                let item = this.orderItemFindItemInGroup(orderItem, this.code);
                if (item) {
                    discount = (item.price * this.percent) / 100.00;
                }

                break;
            }

            //TODO
            case 'gift': {
                if (this.code) {
                    // let item = this.findGift(promo);
                    //
                    // let oi = new OrderItem();
                    // oi.items = [item];

                }
                break;
            }

            case 'unlock': {
                if (this.code) {
                    let cat: Category = this.fetchUnlock(promo);

                    let used = 0;
                    if (cat !== null) {
                        order.orderitems.forEach(oi=>{
                            if (oi.categoryId === this.code) {
                                used += oi.quantity;
                                console.log("used+:", oi.quantity);
                            }
                        });
                    }

                    console.log("used:", used);
                    this.used = used;
                    this.applied = used===0;

                }
                break;
            }

            default: break;
        }

        return discount;
    }

    fetchUnlock(promo: Discount): Category {

        if (promo.unlocks) {
            for (let unlock of promo.unlocks) {
                if (unlock.id == this.code){
                    return unlock;
                }
            }
        }
        return null;
    }

    /*getDisplay() {
        this.display = "";
        if (this.amount) {
            this.display = "-" + this.amount;
        } else if (this.percent) {
            this.display = "-" + this.percent + "%";
        }
        return this.display;
    }*/

    static cloneBenefit(ben: Benefit) {
        let b = new Benefit();
        if (ben.type) b.type = ben.type;
        if (ben.amount) b.amount = ben.amount;
        if (ben.code) b.code = ben.code;
        if (ben.max) b.max = ben.max;
        if (ben.percent) b.percent = ben.percent;
        if (ben.applied) b.applied = ben.applied;
        if (ben.used) b.used = ben.used;
        if (ben.display) b.display = ben.display;

        return b;
    }

    isApplied() {
        return false;
    }


    isDiscount(): boolean{
        if (this.type == "unlock") return false;
        return true;
    }

    isItemDiscount(): boolean{
        switch (this.type)
        {
            case "amount":
                return true;


            default: break;
        }

        return false;
    }

    getDiscountedPrice(item: Item): number {
        return null;
    }

    setMainItemDiscounted(oi: OrderItem) {
        if (oi.groups || oi.groups.length == 0) return;

        oi.groups[0].items.forEach(item=>{
            item.discounted = true;
        });
    }

    orderItemFindItem(oi: OrderItem, itemCode: string) {
        for (let item of oi.items) {
            if (item.id === itemCode) return item;
        }

        return null;
    }

    orderItemFindItemInGroup(oi: OrderItem, groupCode: string) {
        for (let group of oi.groups) {
            if (group.id === groupCode) {
                return group.items[0]; //group main item
            }
        }

        return null
    }

}
