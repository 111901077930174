

import { Data } from "./data";

export class Printer extends Data {

    class: string = "Printer";
    id: string;
    ip: string;
    model: string;
    name: string;
    enabled: boolean;
    dualReceipt: boolean;
    apply: string;
    showNo: boolean;
    vendor: string;
    locale: string;
    usage: string;
    base: boolean;

}