import { Store } from './store';
import { Brand } from './brand';
import { Address } from './address';
import { Member } from './member';
import { Data } from './data';
import { OrderItem } from './order-item';
import { Discount } from './discount';
import { Location } from "./location";
import { Printer } from './printer';
import { Offer } from './offer';
export class Order extends Data {

    brandId: number = 0;
    brand: Brand;
    class: string = "Order";
    id: string;
    backendId: string;
    grandTotal: number = 0;
    total: number = 0;
    discount: number = 0;
    status: string;
    orderitems: OrderItem[] = [];
    storeId: number;
    store: Store;
    orderNo: string;
    member: Member;
    create: number;
    update: number;
    type: string;
    memberNumber: number;
    address: Address;
    pickup: number;
    pickupId: number;
    phone: string;
    session: string;
    spot: string;
    takeout: boolean;
    paid: boolean;
    staff: string;
    station: string;
    stationId: string;
    payment: string;
    payments: any[];
    remark: string;
    discounts: Discount[] = [];
    expire: number;
    test: boolean;
    reprint: number;
    extra: any;
    qrcode: string;
    posSync: boolean;
    error: string;
    location: Location;
    voidCode: string;
    voidReason: string;
    locale: string;
    prepare: number;
    subpay: string;
    uuid: string;
    tax: number;
    offers:Offer[] = [];
    newOffers:Offer[] = [];
    handling: number = 0;
    callNo: string;
    multi: boolean ;
    printers: Printer[] = []
    promotions: Discount[];
    discountCode: string;
    discountRate: number;
    gifts: OrderItem[];
    usablePoints: number = 0;
    usedPoints: number = 0; //order to use points
    reservedPoints: number = 0; //points reserved in server
    payFee: number = 0;

    protected toProperty(name: string, value) {
        if (name == "orderitems") {
            return Data.toDataArray(OrderItem, value);
        }
        else if (name == "location") {
            return Data.toData(Location, value);
        }else if (name == "brand") {
            return Data.toData(Brand, value);
        }else if (name == "store") {
            return Data.toData(Store, value);
        }else if(name == "offers" || name == "newOffers"){
            return Data.toDataArray(Offer, value);
        }
        else {
            return super.toProperty(name, value);
        }
    }

    calculate() {
        var grandTotal = 0;

        for (let oi of this.orderitems) {

            var oiTotal = 0;

            for (let group of oi.groups) {

                var items = group.selected;

                oiTotal += items[0].price;

            }

            oi.total = oiTotal;
            grandTotal += oiTotal;
        }

        this.grandTotal = grandTotal;
    }

    static flattenItems(order: Order) {

        for (let oi of order.orderitems) {
            OrderItem.flattenItems(oi);
        }

    }

    static getCount(order:Order): number
    {
        let quantities = {};
        let count = 0;
        for (let oi of order.orderitems) {

            if (!OrderItem.isSet(oi)) {

                count += oi.quantity;
                let t = oi.quantity * oi.total;
                //total += MathUtils.round(t, 2);

                let mainItemId = oi.items[0].id;
                let quantity = quantities[mainItemId];
                if (!quantity) quantity = 0;
                quantity += oi.quantity;
                quantities[mainItemId] = quantity;
            } else {

                count += oi.quantity;
                //let t = oi.quantity * oi.total;
                //total += MathUtils.round(t, 2);

            }
        }
        return count;
    }
    static toOrdersData(orders:Order[]):any[]{
        var result:any[] = [];
        for(let order of orders){
            var tmp = Order.toOrderData(order);
            result.push(tmp);
        }

        return result;
        
    }

    static toOrderData(order:Order):any{
        var json ={};
        json["total"] = order.total;
        json["grandTotal"] = order.grandTotal;
        json["discount"] = order.discount;
        json["storeId"] = order.storeId;
        json["type"] = order.type;
        json["uuid"] = order.uuid;
        json["payFee"] = order.payFee;
        if(order.usedPoints > 0){
            json["usedPoints"] = order.usedPoints;

        }
        json["orderitems"] = OrderItem.toOrderItemsData(order.orderitems);
        json["offers"] = Offer.toOffersData(order.offers);
        
        
        if(order.expire){
            json["expire"] = order.expire;

        }

        return json;
        
    }

}
