import { Data } from './data';
import { Category } from './category';
import { Benefit } from './benefit';
import { Condition } from './condition';
import { Item } from "./item";

export class Discount extends Data {
    class: string = "Discount";
    id: string;
    name: string; //discount name
    groupId: number; 
    brandId: number;
    storeId: number;   
    type: string; //discount type
    enabled: boolean;
    start: Date; //available time
    end: Date; //available time
    conditions: Condition[]; //discount conditions
    benefits: Benefit[]; //discount detail
    amount: number; //discount total amount
    backendId: string; //discount backend ID
    quantity: number; //
    desc: string; //display name
    available: number = 0;
    level: string; //discount level
    unlocks: Category[]; //Categories can be unlocked
    alone: boolean = false;

    protected toProperty(name: string, value) {

        if (name == "conditions") {
            return Data.toDataArray(Condition, value);
        }else if(name == "benefits"){
            return Data.toDataArray(Benefit, value);
        }else if(name == "unlocks"){
            return Data.toDataArray(Category, value);
        }

        return value;
    }
}