import { Data } from './data';
export class Setting extends Data {
    autoPickup:boolean;
    catLayout:string;
    itemLayout:string;
    deliveryCharge:number;
    hidePrice:boolean;
    maxDeliveryTotal:number;
    maxItems:number;
    minDeliveryTotal:number;
    orderExpire:number;
    pickupCharge:number;
    pickupItemCharge:number;
    pickupTime:boolean;
    preselectItem:boolean;
    qrPickup:boolean;
    quickCheckout:boolean;
    quickModifier:boolean;
    quickSingleItem:boolean;
    serviceCharge:number;
    setLayout:string;
    tax:number;
    taxIncluded:boolean;
    tcPrompt:string;
    hideHistory:boolean;
    inventory:string;
    lockCode:string;
    hideIgName:boolean;
    hideTotal:boolean;
    emailRequired:boolean;
    phoneRequired:boolean;
    enableSearch:boolean;
    pickUpTimeLimit: number;
    chargeDesc:string;
    qrcodeScan:boolean;
}