import { Data } from './data';
import * as moment from 'moment';

export class Slot extends Data {

    class: string = "Slot";
    type: string;
    name: string;
    id: string;
    start: number;
    end: number;
    weekday: number;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;

    
    public static isWithinDayRange(slot: Slot, time: number) : boolean{



        var start = moment(slot.startDate);
        var end = moment(slot.endDate);
        if(slot.startTime && slot.endTime){
            var startTime = moment(slot.startTime, "HH:mm");
            var endTime = moment(slot.endTime, "HH:mm");
            end = endTime < startTime ? moment((slot.endDate + " " + slot.endTime), "YYYY-MM-DD HH:mm") : moment(slot.endDate);
        }

        end.add("day", 1);

        
        var now = moment(time);

        //console.log("is within?", now.toISOString(), start.toISOString(), end.toISOString());

        return Slot.between(start, end, now);


    }

    //inclusive start, exclusive end
    private static between(start: any, end: any, time: any){

        var ms = time.valueOf();

        return ms >= start.valueOf() && ms < end.valueOf();

    }


    public static isTimeOpen(slot: Slot, time: number, checkWeekdays:boolean): boolean {
        
        var startTime = slot.startTime;
        var endTime = slot.endTime;

        
        var start = moment(startTime, "HH:mm");
        var end = moment(endTime, "HH:mm");

        var now = moment(time);

        var weekday = now.day();
        var overNextDay = end < start;
        var isOverDay = overNextDay && (slot.weekday - weekday == -1 || slot.weekday - weekday == 6)

        if (checkWeekdays && weekday != slot.weekday && slot.weekday < 10) {
            
            console.log("wrong weekday");
            if(!isOverDay){
                return false;

            }
        }

        if(!Slot.isOpenType(slot)){
            //if slot time is closed and it's matching day, always closed
            return false;
        }

        console.log("right weekday", slot.weekday);

        start.year(now.year());
        start.month(now.month());
        start.dayOfYear(now.dayOfYear());

        end.year(now.year());
        end.month(now.month());
        end.dayOfYear(now.dayOfYear());

        if(overNextDay){
            console.log("stepped over time to next day!!!");
            if(weekday == 0){
                if(now > end && isOverDay){
                    end = end.add(24, 'hours');
                }else if(isOverDay){
                    start = start.add(-24, 'hours');
                }
            }else if(now > end ){
                end = end.add(24, 'hours');
            }else if(isOverDay || slot.weekday > 10){
                start = start.add(-24, 'hours');
            }
        }

        console.log("start moment", start.toDate());
        console.log("now moment", now.toDate());
        console.log("end moment", end.toDate());

        var within = Slot.between(start, end, now);

        return within;
    }

    public static isWeekdayOpen(slot: Slot, time: number): boolean {

        var now = moment(time);

        var weekday = now.day();
        if (weekday != slot.weekday) {

            return false;
        }

        var startTime = slot.startTime;
        var endTime = slot.endTime;

        if (startTime && endTime) return Slot.isOpenType(slot);

        return false;
    }

    public static isDateOpen(slot: Slot, time: number): boolean {
        
        var now = moment(time);

        var startTime = slot.startTime;
        var endTime = slot.endTime;

        if (startTime && endTime) return Slot.isOpenType(slot);

        return false;
    }

    public static isOpenType(slot: Slot){

        if(slot.type){
            return "open" == slot.type;
        }

        return true;

    }

}