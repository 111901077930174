import { Data } from './data';
import { Item } from './item';

export class ItemGroup extends Data {

    class: string = "ItemGroup";
    id: string;
    name: string;
    items: Item[];
    modifier: boolean = false;
    optional: boolean = false;
    tags: string[] = [];
    choices: number = 1;
    repeat: boolean = false;
    skippable: boolean = false;
    min: number;
    max: number;
    backendId: string;
    selected: Item[];
    cmap: any = {};

    origin: ItemGroup;
    printers: string[] = [];
    parentItems: Item[];
    defaultId: string;

    filter: string;
    filter2: string;
    filters: string[];
    filters2: string[];

    getSelectedNames() {

        var names = [];
        for (let item of this.selected) {

            names.push(item.name);
        }

        return names.join();
    }

    getSelectedItemIds() {

        var names = [];
        for (let item of this.selected) {

            names.push(item.id);
        }

        return names.join();
    }

    protected toProperty(name: string, value) {

        switch (name) {

            case "items":
                value = Data.toDataArray(Item, value);
                break;
            default:
                value = super.toProperty(name, value);
                break;
        }

        return value;
    }

    public static getPublishedItems(ig: ItemGroup, now: number) {

        return Item.filterPublished(ig.items, now);

    }

    public static getPublishedItemsWithMap(catId: string, ig: ItemGroup, index: number, filter?: any) {
        var catId = catId;
        // catId = catId + "." + index.toString();
        return Item.filterPublishedItems(ig.items, ig.cmap, catId, index, filter);
    }

    static toItemGroupsData(itemGroups: ItemGroup[]): any[] {
        var results: any[] = [];
        for (let itemGroup of itemGroups) {
            var tmp = ItemGroup.toItemGroupData(itemGroup);
            results.push(tmp);
        }
        return results;

    }

    static toItemGroupData(itemGroup: ItemGroup): any {
        var json = {};
        json["id"] = itemGroup.id;
        json["items"] = Item.toItemsData(itemGroup.items);
        json["name"] = itemGroup.name;
        return json;

    }


}
