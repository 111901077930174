
import { Data } from './data';
import { Reward } from './reward';
import { OrderItem } from './order-item';

export class Offer extends Data {
    class: string = "Offer";
    id: number;
    name: string;
    hint: string;
    publihed: boolean;
    backendId: string;
    create:number;
    start:number;
    expire:number;
    reward :Reward;
    orderitems:OrderItem[] = [];


    protected toProperty(name: string, value) {

        if (name == "reward") {
            return Data.toData(Reward, value);
        }

        return value;
    }

    static toOffersData(offers:Offer[]):any[]{
        var result:any[] = [];
        for(let offer of offers){
            var tmp = Offer.toOfferData(offer);
            result.push(tmp);
        }

        return result;
        
    }

    static toOfferData(offer:Offer):any{
        var json ={};
        json["id"] = offer.id;
        json["name"] = offer.name;
        json["hint"] = offer.hint;
        json["publihed"] = offer.publihed;
        json["backendId"] = offer.backendId;
        json["orderitems"] = OrderItem.toOrderItemsData(offer.orderitems);
        json["reward"] = Reward.toRewardData(offer.reward);


        return json;
        
    }


    
}