import { Data } from './data';
import { Category } from './category';
import { ItemGroup } from './item-group';
import { Item } from './item';
import { Discount } from './discount';

export class OrderItem extends Data {

    class: string = "OrderItem";
    id: string;
    total: number = 0;
    //items: Item[] = [];
    create: number;
    
    groups: ItemGroup[] = [];
    groupIds: string[] = [];
    itemIds: string[] = [];

    categoryType: string;
    categoryId: string;
    category: Category;
    quantity: number = 1;
    combo: boolean = false;
    modifiable: boolean = false;

    callNo: string;
    receipt: boolean;
    storeId: number;

    handling: number = 0;
    discount: number = 0;
    points: number = 0;

    discountCode: string;
    discountDesc: string;
    discounts: Discount[] = [];
    grandTotal: number;

    barcode: string;
    message: string;

    takeout: boolean;


    protected toProperty(name: string, value) {
        if (name == "items") {
            return Data.toDataArray(Item, value);
        } else if (name == "groups") {
            return Data.toDataArray(ItemGroup, value);
        } else {
            return super.toProperty(name, value);
        }
    }

    static isSet(oi: OrderItem) : boolean{

        if(oi.combo) return true;

        if (oi.groups && oi.groups.length > 1) {
            return true;
        }

        let mainItem: Item = OrderItem.getMainItem(oi);

        if(mainItem){
            return Item.isSet(oi.category, mainItem);
        }
        return false;

    }

    static getMainItem(oi: OrderItem): Item{

        let item: Item;

        if (oi.items && oi.items.length > 0) {
            item = oi.items[0];
        } else {
            item = oi.groups[0].items[0];
        }
        
        /*
        if(item && item.origin){
            item = item.origin;
        }*/

        return item;
    }

    items: Item[] = [];
    static flattenItems(oi: OrderItem){
        
        /*
        var items = [];
        for(let group of oi.groups){
            for(let item of group.items){
                items.push(item);
            }
        }
        oi.items = items;
        */
        oi.items = OrderItem.getFlattenItems(oi, true);
    }

    static getFlattenItems(oi: OrderItem, includeMods: boolean) : Item[]{
        
        var items = [];
        for(let group of oi.groups){
            
            if(!group.items) continue;

            for(let item of group.items){
                items.push(item);

                if(includeMods && item.mgroups){
                    for(let mig of item.mgroups){
                        for(let item of mig.items){
                            item.modifier = true;
                            items.push(item);
                        }
                    }
                }

            }
        }
       
        return items;
    }

    static toOrderItemsData(orderItems:OrderItem[]):any[]{
       var result:any[] = [];
        for(let orderItem of orderItems){
            var tmp = OrderItem.toOrderItemData(orderItem);
            result.push(tmp);
        }

        return result;
        
    }

    static toOrderItemData(orderItem:OrderItem):any{
        var json ={};
        json["total"] = orderItem.total;
        json["groups"] = ItemGroup.toItemGroupsData(orderItem.groups);
        json["quantity"] = orderItem.quantity;
        json["category"] = Category.toCategoryData(orderItem.category);
        json["categoryId"] = orderItem.categoryId;

        return json;
        
    }
    

}
