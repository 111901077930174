import { Data } from './data';
import { ItemGroup } from './item-group';
import { OpeningHours } from './opening-hours';
export class Category extends Data {

    class: string = "Category";
    id: string;
    name: string;
    prefix: string;
    groupIds: string[] = [];
    groups: ItemGroup[] = [];
    published: boolean;
    periods: string[];
    start: number;
    end: number;
    dinein: boolean;
    layout: string;
    suspended: boolean;
    openings: OpeningHours;
    users: string[];
    type: string;
    categoryIds: any[];

    protected toProperty(name: string, value) {

        switch (name) {

            case "groups":
                value = Data.toDataArray(ItemGroup, value);
                break;
            default:
                value = super.toProperty(name, value);
                break;

        }

        return value;
    }

    get displayName(): string {

        if (this.prefix)
            return this.prefix + this.name;

        return this.name;
    }

    static toCategoryData(category :Category):any{
        var json = {};
        json["id"] = category.id;
        json["name"] = category.name;

        return json;
    }
}

