import { Data } from './data';
import * as moment from 'moment';
import { Slot } from './slot';

export class OpeningHours extends Data {
    
    class: string = "OpeningHours";
    weekdays: any[];
    overwrites: any[];
    holidays: any[];
    always: boolean;

    ph1: Slot[];
    ph2: Slot[];

    public static isDateOpen(oh: OpeningHours, time: number): boolean {


        if(!oh) return true;
        if(oh.always) return true;

        //check ovewrites, holidays, and then normal hours

        var day = new Date(time);
        console.log("day", day.getDate());

        if (oh.overwrites) {
            for (let slot of oh.overwrites) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within overwrites", slot);
                    if (Slot.isOpenType(slot)) {
                        console.log("overwrite true");
                        return true;
                    } else {
                        console.log("overwrite false");
                        return false;
                    }
                }
            }


        }

        var ph1Slot;
        var ph2Slot;

        if (oh.holidays) {
            for (let slot of oh.holidays) {

                if (slot.weekday == 101) {

                    ph1Slot = slot;
                    console.log("ph1", slot);
                } else if (slot.weekday == 102) {

                    ph2Slot = slot;
                    console.log("ph2", slot);
                } else if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within real holiday", slot);
                    if (Slot.isOpenType(slot)) {
                        console.log("holiday true");
                        return true;
                    } else {
                        console.log("holiday false");
                        return false;
                    }
                }
            }


        }

        if (oh.ph1 && ph1Slot) {
            for (let slot of oh.ph1) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within holiday1", slot);
                    if (Slot.isOpenType(ph1Slot)) {
                        console.log("holiday true");
                        return true;
                    } else {
                        console.log("holiday false");
                        return false;
                    }
                }
            }


        }

        if (oh.ph2 && ph2Slot) {
            for (let slot of oh.ph2) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within holiday2", slot);
                    if (Slot.isOpenType(ph2Slot)) {
                        console.log("holiday true");
                        return true;
                    } else {
                        console.log("holiday false");
                        return false;
                    }
                }
            }


        }


        if (oh.weekdays) {
            for (let slot of oh.weekdays) {
                if (Slot.isWeekdayOpen(slot, time)) {
                    console.log("weekday open");
                    return true;
                }
            }
        } else {
            console.log("weekdays not set, open");
            return true;
        }

        console.log("none match closed", oh);
        return false;

    }

   

    public static isTimeOpen(brandOh:OpeningHours, oh: OpeningHours, time: number): boolean {
        
        if(!oh) return true;
        if(oh.always) return true;
        
        console.log("brand OpeningHours", brandOh);

        var day = new Date(time);
        console.log("day", day.getDate());

        if (oh.overwrites) {
            for (let slot of oh.overwrites) {
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within overwrites", slot);
                    if (Slot.isTimeOpen(slot, time, false)) {
                        console.log("overwrite true");
                        return true;
                    } else {
                        console.log("overwrite false");
                        return false;
                    }
                }
            }


        }

        var ph1Slot;
        var ph2Slot;

        if (oh.holidays) {
            for (let slot of oh.holidays) {

                if (slot.weekday == 101) {

                    ph1Slot = slot;
                    console.log("ph1", slot);
                } else if (slot.weekday == 102) {

                    ph2Slot = slot;
                    console.log("ph2", slot);
                } else if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within holiday", slot);
                    if (Slot.isTimeOpen(slot, time, false)) {
                        console.log("holiday true");
                        return true;
                    } else {
                        console.log("holiday false");
                        return false;
                    }
                }
            }


        }

        if (brandOh && brandOh.ph1 && ph1Slot) {
            for (let slot of brandOh.ph1) {
                    slot.startTime = ph1Slot.startTime;
                    slot.endTime = ph1Slot.endTime;
                if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within holiday1", slot);
                    if (Slot.isTimeOpen(ph1Slot, time, false)) {
                        console.log("holiday true");
                        return true;
                    } else {
                        console.log("holiday false");
                        return false;
                    }
                }
            }


        }

        if (brandOh && brandOh.ph2 && ph2Slot) {
            for (let slot of brandOh.ph2) {
                slot.startTime = ph2Slot.startTime;
                slot.endTime = ph2Slot.endTime;

                if (Slot.isWithinDayRange(slot, time)) {
                    console.log("within holiday2", slot);
                    if (Slot.isTimeOpen(ph2Slot, time, false)) {
                        console.log("holiday true");
                        return true;
                    } else {
                        console.log("holiday false");
                        return false;
                    }
                }
            }



        }

        
        if (oh.weekdays) {
            for (let slot of oh.weekdays) {
                if (Slot.isTimeOpen(slot, time, true)) {
                    console.log("weekday open");
                    return true;
                }
            }
        } else {
            console.log("weekdays not set, open");
            return true;
        }

        console.log("should close");
        return false;


    }


}